<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>
  <div class="col-lg-9 mt-lg-0 mt-4">
    <user-profile
      v-model:profilePicture="fields.profilePicture"
      v-model:profilePictureData="fields.profilePictureData"
      :full-name="fields.companyName"
    />

    <form @submit.prevent="submit">
      <base-fieldset
        id="company-info"
        title="Bedrijfsinformatie"
      >
        <company-info
          v-model:companyName="fields.companyName"
          v-model:address="fields.address"
          v-model:zipCode="fields.zipCode"
          v-model:city="fields.city"
          v-model:country="fields.country"
          v-model:website="fields.website"
          v-model:description="fields.description"
          v-model:shortDescription="fields.shortDescription"
          v-model:phoneNumber="fields.phoneNumber"
          v-model:emailAddress="fields.emailAddress"
          :show-update="!isNewCompany"
        />
      </base-fieldset>

      <base-fieldset
        id="company-location"
        title="Locatie"
      >
        <company-location
          v-model:address="fields.address"
          v-model:zipCode="fields.zipCode"
          v-model:city="fields.city"
          v-model:country="fields.country"
          v-model:latitude="fields.latitude"
          v-model:longitude="fields.longitude"
          :show-update="!isNewCompany"
        />
      </base-fieldset>
      
      <base-fieldset
        id="company-socials"
        title="Socials"
      >
        <company-socials
          v-model:facebook="fields.facebook"
          v-model:linkedIn="fields.linkedIn"
          v-model:twitter="fields.twitter"
          v-model:instagram="fields.instagram"
          :show-update="!isNewCompany"
        />
      </base-fieldset>

      <base-fieldset
        id="user-permissions"
        intro="Gebruikers binnen de app kunnen verschillende rechten hebben, vul hieronder de juiste
               rechten in"
        title="Rechten"
      >
      </base-fieldset>
      
      <base-fieldset
        id="company-users"
        title="Werknemers"
      >
        <company-users 
          ref="companyUsersRef"
          v-model:users="fields.companyUsers"
          :company-id="fields.id"
          :show-update="!isNewCompany"
        />
      </base-fieldset>
      
      <div
        v-if="!isNewCompany"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder bedrijf</h5>
              <p class="text-sm mb-0">
                Wanneer een bedrijf verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete Bedrijf
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <material-button
        v-if="isNewCompany"
        color="primary"
        variant="gradient"
        class="float-end mt-4 mb-0 mx-4"
      >
        Opslaan
      </material-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { add, edit, get, remove } from '@/api/providers/company';
import { addUser, removeUser, updateUser } from '@/api/providers/company';
import { parseFullName } from '@/helpers/users';
import BaseFieldset from "@/components/Forms/BaseFieldset.vue";
import CompanyInfo from "@/components/Company/Edit/CompanyInfo.vue";
import CompanyLocation from "@/components/Company/Edit/CompanyLocation.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import SideNav from "@/components/UI/SideNav.vue";
import CompanySocials from "@/components/Company/Edit/CompanySocials.vue";
import CompanyUsers from '../../components/Company/Edit/CompanyUsers.vue';
import UserProfile from "@/components/AppUsers/Edit/UserProfile.vue";

import { createYesNoSweetAlert } from "@/helpers/sweetalert";

export default {
  name: 'CompanyEdit',

  components: {
    BaseFieldset,
    CompanyInfo,
    CompanyLocation,
    CompanySocials,
    CompanyUsers,
    MaterialButton,
    SideNav,
    UserProfile
  },

  data: () => ({
    fields: {
      id: 0,
      companyName: "",
      shortDescription: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      longitude: 0,
      latitude: 0,
      phoneNumber: "",
      emailAddress: "",
      website: "",
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: "",
      description: "",
      tagsList: [],
      organizationId: 0,
      profilePicture: '',
      profilePictureData: "",
      companyUsers: []
    },

    loading: false,
  }),

  computed: {
    ...mapState('account', [
      'auth',
    ]),

    fullName() {
      const { firstName, insertion, lastName } = this.fields;
      return parseFullName(firstName, insertion, lastName);
    },

    isNewCompany() {
      return this.$route.params?.id === undefined;
    },

    navigationItems() {
      return [
        {
          href: '#company-info',
          icon: 'business',
          name: 'Bedrijfsinformatie',
        },
        {
          href: '#company-location',
          icon: 'map',
          name: 'Bedrijfsinformatie',
        },
        {
          href: '#company-socials',
          icon: 'public',
          name: 'Social',
        },
        ...(!this.isNewCompany
          ? [{
              href: '#delete',
              icon: 'delete',
              name: 'Verwijder bedrijf',
            }]
          : []
        ),
      ];
    },

    submitData() {
      return {
        ...this.fields,
        
        // Attempting to update a company's users through the company endpoint
        // Causes entity state errors, so that is done through a seperate endpoint.
        companyUsers: null,
        ...(
          this.isNewCompany
            ? {}
            : {
              id: this.$route.params.id,
            }
        ),
        organizationId: this.auth.organizationId,
      };
    },
  },

  async mounted() {
    await this.loadCompanyData();
    this.setSubtitle(
      this.fields.companyName !== ""
        ? this.fields.companyName
        : "Nieuw"
    );
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
      addError: 'addError'
    }),
    ...mapActions('appHeader', [ 'setSubtitle' ]),

    addCompanyMembers(companyId) {
      const users = this.fields.companyUsers ?? [];

      return new Promise((resolve, reject) => {
        users.filter(u => u.isNew && !u.isDeleted).forEach(u => {
          addUser(companyId, u.id, u.canEdit)
            .catch(() => reject());
        });
        resolve();
      })
    },

    removeCompanyMembers(companyId) {
      const users = this.fields.companyUsers ?? [];

      return new Promise((resolve, reject) => {
        users.filter(u => u.isDeleted && !u.isNew).forEach(u => {
          removeUser(companyId, u.id)
            .catch(() => reject());
        });
        resolve();
      });
    },

    submitUpdatedCompanyMembers(companyId) {
      const users = this.fields.companyUsers ?? [];

      return new Promise(function(resolve, reject)
      {
        users.filter(u => u.isUpdated && !u.isDeleted && !u.isNew).forEach(u => {
          updateUser(
            companyId,
            u.id,
            u.canEdit,
            u.userFunction != undefined ? u.userFunction : ''
          )
            .catch(() => reject());
        });
        resolve();
      });
    },

    showFirstErrorMessage(errors) {
      if (errors.length > 0) {
        this.addError(errors[0]);
      }
    },

    async addCompany() {
      this.loading = true;

      try {
        const createdEntity = await add(this.submitData);
        this.$refs.companyUsersRef.submit(createdEntity.id);
        
        this.handleSaveSuccess();
        this.navigateToCreatedCompany(createdEntity.id);
      } 
      catch (error) {
        this.addError(error);
      }

      this.loading = false;
    },

    async editCompany() {
      try {
        const updatedEntity = await edit(this.$route.params?.id, this.submitData);
        this.$refs.companyUsersRef.submit(updatedEntity.id);

        this.handleSaveSuccess();
      }
      catch (error) {
        this.addError(error);
      }

      this.loading = false;
    },

    async submitCompanyMembers(companyId) {
      try {
        this.updateCompanyMembers(companyId);
      }
      catch (errors) {
        this.showFirstErrorMessage(errors);
      }
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        if (!(key in this.fields)) {
          return;
        }

        this.fields[key] = value;
      });
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.companyName} is verwijderd.`,
        icon: {
          component: 'apartment',
        },
      });

      this.navigateToCompanyOverview();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.companyName} is opgeslagen.`,
        icon: {
          component: 'apartment',
        },
      });
    },

    async loadCompanyData() {
      if (this.isNewCompany) {
        return;
      }

      await get(this.$route.params.id)
        .then(this.handleGetSuccess)
        .catch();
    },

    navigateToCompanyOverview() {
      this.$router.push({
        name: 'companies'
      });
    },

    navigateToCreatedCompany(companyId) {
      this.$router.push({
        name: 'edit-company',
        params: { id: companyId }
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Wilt u ${this.fields.companyName} verwijderen?`,
        () => remove(this.$route.params?.id)
                .then(this.handleRemoveSuccess)
      );
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewCompany) {
        this.addCompany();
      } else {
        this.editCompany();
      }

      this.loading = false;
    },
  },
}
</script>
